// This dictionary contains 1 untranslated or inherited localization strings.
// These strings are commented out. Uncomment and edit them if you want to add your translations.
import { localization } from "../localizationManager";

export var plStrings = {
  groupButton: "Grupuj według mnie",
  ungroupButton: "Rozgrupuj według mnie",
  selectButton: "Wybierz",
  hideColumn: "Ukryj kolumnę",
  showColumn: "Pokaż kolumnę",
  makePrivateColumn: "Uczyń kolumnę prywatną",
  makePublicColumn: "Uczyń kolumnę publiczną",
  moveToDetail: "Przenieś do szczegółów",
  showAsColumn: "Pokaż jako kolumnę",
  filterPlaceholder: "Szukaj...",
  removeRows: "Usuń wiersze",
  showLabel: "Pokaż",
  entriesLabel: "wejścia",
  visualizer_text: "Teksty w tabeli",
  visualizer_wordcloud: "Chmura słów",
  visualizer_histogram: "Histogram",
  visualizer_number: "Średnia",
  visualizer_choices: "Tabela",
  visualizer_selectBase: "Wykres",
  visualizer_matrix: "Wykres",
  chartType_bar: "Słupkowy",
  chartType_vbar: "Słupkowy pionowy",
  chartType_stackedbar: "Słupkowy skumulowany",
  chartType_doughnut: "Oponka",
  chartType_pie: "Tarta",
  chartType_scatter: "Rozrzut",
  chartType_gauge: "Skala",
  chartType_bullet: "Pocisk",
  hideButton: "Ukryj",
  makePrivateButton: "Uczyń prywatnym",
  makePublicButton: "Uczyń publicznym",
  showButton: "Pokaż",
  filter: "Filtruj",
  resetFilter: "Resetuj filtr",
  changeLocale: "Zmień lokalizację",
  clearButton: "Wyczyść",
  addElement: "Wybierz pytanie do pokazania...",
  defaultOrder: "Domyślne sortowanie",
  ascOrder: "Rosnąco",
  descOrder: "Malejąco",
  showMinorColumns: "Pokaż mniejsze kolumny",
  otherCommentTitle: "Inne elementy i komentarze",
  showPercentages: "Pokaż procenty",
  hidePercentages: "Ukryj procenty",
  pdfDownloadCaption: "PDF",
  xlsxDownloadCaption: "Excel",
  csvDownloadCaption: "CSV",
  saveDiagramAsPNG: "Pobierz diagram jako PNG",
  hideEmptyAnswers: "Ukryj puste odpowiedzi",
  showEmptyAnswers: "Pokaż puste odpowiedzi",
  "topNValueText-1": "Wszystkie odpowiedzi",
  topNValueText5: "Pierwsze 5 odpowiedzi",
  topNValueText10: "Pierwsze 10 odpowiedzi",
  topNValueText20: "Pierwsze 20 odpowiedzi",
  hideMissingAnswers: "Ukryj nieodpowiedziane pytania",
  showMissingAnswers: "Pokaż nieodpowiedziane pytania",
  missingAnswersLabel: "Nieodpowiedziane pytania",
  noVisualizerForQuestion: "Ten typ pytania nie został jeszcze zwizualizowany",
  noResults: "Nie ma jeszcze wyników",
  showPerValues: "Według wartości",
  showPerColumns: "Według kolumn",
  answer: "Odpowiedź",
  correctAnswer: "Odpowiedź poprawna: ",
  percent: "Procent",
  responses: "Odpowiedzi"
};

//Uncomment these two lines on creating a translation file. You should replace "en" and enStrings with your locale ("fr", "de" and so on) and your variable.
localization.locales["pl"] = plStrings;
localization.localeNames["pl"] = "Polish";
